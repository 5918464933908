body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}


.topocart-logo {
  display: none;
}

.login {
  display: flex;
  align-items: stretch;
  height: 100vh;
  width: 100vw;
}

.image-background {
  flex: 1;
  background-image: url('/public/images/background-left-container.png');
  background-size: cover;
  background-position: center;
}

.login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('/public/images/background-right-container.png');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
}

.login-container h2 {
  font-size: 45px;
  margin-top: 0;
  margin-bottom: 15px;
}

.login-button {
  background-color: black;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 14px;
  padding: 12px 80px;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.5s ease-in-out;
  cursor: pointer;
}

.login-button:hover {
  background-color: #202020;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 2);
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-group label {
  align-self: flex-start;
  margin-bottom: 1px;
  font-weight: bold;
  width: 62%;
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.input-group input {
  width: 40%;
  padding: 18px 20px;
  border: 1px solid;
  border-radius: 15px;
  outline: none;
}

.input-group input::placeholder {
  font-size: 12px;
  font-weight: bold;
}

.input-remember-me {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 44.5%;
  margin: 0 auto;
  font-weight: 500;
}

.input-group-select {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
}

.input-group-select label {
  font-weight: bold;
}

.input-group-select select {
  margin-top: 8px;
  padding: 10px 6px;
  border: solid 1px black;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
}

.input-group-select option {
  border: solid 1px black;
  border-radius: 10px;
}

.error-message {
  position: absolute;
  color: #D8000C;
  background-color: #FFD2D2;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-shadow: 0 0 5px rgba(216, 0, 12, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  visibility: hidden;
  top: 10rem;
}

.error-message.show {
  opacity: 1;
  visibility: visible;
}

.error-shown .login-button  {
  transform: translateY(33px); 
}

.espaco-vazio {
  height: 20px; 
  width: 100%;
}

.password-container {
  display: flex;
  width: 42.5%;
  align-items: center;
  border: 1px solid; 
  border-radius: 15px; 
  padding: 0 20px 0 0px; 
  position: relative; 
}

.password-container input {
  border: none; 
  outline: none; 
  flex-grow: 1; 
  padding-right: 0px; 
}

@media (max-width: 348px) {
  .image-background {
    display: none;
  }

  .input-remember-me {
    width: 63%;
    font-size: 12px;
  }

  .input-group input {
    width: 50%;
    padding: 15px 20px;
  }

  .input-group label {
    font-size: 12px;
    width: 55.5%;
    margin-bottom: 1px;
  }

  .topocart-logo {
    display: block;
    width: 20%;
    position: relative;
    top: 4.5rem;
  }

  .login-container h2 {
    font-size: 30px;
  }

  .login-button  {
    font-size: 15px;
    border-radius: 14px;
    padding: 12px 50px;
  }
}

@media (max-width: 768px) {

  .image-background {
    display: none;
  }

  .input-remember-me {
    width: 63%;
    font-size: 12px;
  }

  .input-group input {
    width: 50%;
    padding: 15px 20px;
  }

  .password-container  {
    width: 55%;
  }

  .input-group label {
    font-size: 12px;
    width: 55.5%;
    margin-bottom: 1px;
  }

  .topocart-logo {
    display: block;
    width: 20%;
    position: relative;
    top: 4.5rem;
  }

  .login-container h2 {
    font-size: 30px;
  }

  .login-button  {
    font-size: 15px;
    border-radius: 14px;
    padding: 12px 50px;
  }

  .input-group-select {
    margin: 20px 0px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .error-message {
    top: 8rem;
    font-size: 12px;
  }

  .error-message.show {
    opacity: 1;
  }

}

@media (min-width: 1280px) and (max-width: 1440px) {
  .error-message {
    top: 3rem; 
    font-size: 12px; 
  }

  .login-container h2 {
    font-size: 35px;
  }
  
  .login-button  {
    font-size: 15px;
  }
  
  .input-group label {
    font-size: 13px;
    width: 62%;
    margin-bottom: 1px;
  }
  
  .input-group input {
    padding: 14px 20px;
  }
  
  .input-remember-me {
      font-size: 13px;
  }
  
  .input-group-select label {
    font-size: 14px;
  }
  
  .input-group-select select {
    font-size: 12px;
  }
}
