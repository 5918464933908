.custom-select-box {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 70vh;
  background-color: white;
  padding: 20px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  border-radius: 8px;
}

.search-button-box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.search-button-box input {
  width: 100%;
  padding: 15px;
  border: solid 1px;
  border-radius: 15px;
}

.close-button {
  border: 0;
  font-size: 30px !important;
  padding-left: 1.5rem;
  cursor: pointer;
  background-color: white;
  transition: opacity 0.3s ease;
}

.close-button:hover {
  opacity: 0.7;
}

.routes-bi {
  text-align: left;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
}

.routes-bi:hover {
  background-color: #dddddd;
  border-radius: 5px;
}

.route-selected {
  margin-top: 5px;
  border: solid 1px;
  font-size: 14px;
  border-radius: 12px;
  width: 100%;
  height: 150%;
  cursor: pointer;
  background-color: white;
  transition: box-shadow 0.3s ease;
}

.route-selected:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
}

.routes-list {
  overflow-y: auto;
  max-height: 54vh;
}

.routes-list::-webkit-scrollbar {
  width: 10px;
}

.routes-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.routes-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

.routes-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tabs-container {
  display: flex;
  width: 100%;
}

.tabs {
  display: flex;
  align-items: left;
  margin-bottom: 1rem;
  gap: 1rem;
  width: 100%;
}

.tabs button {
  border: 0;
  background: #dddddd;
  height: 2rem;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: font-weight 0.2s ease, background-color 0.3s ease;
}

.active,
.tabs button:hover {
  background: #bfbfbf;
}

.tab-bold {
  font-weight: bold;
}

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input-container input {
  padding-right: 30px;
}

.search-icon {
  position: absolute;
  right: 10px;
  color: black;
}

.no-access-message-container {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  flex-direction: column;
}

.logout-button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: white;
  gap: 0.3rem;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.logout-button:hover {
  color: #555;
}

.logout-button-no-access {
  display: flex;
  align-items: center;
  border: 0;
  background-color:#dddddd;
  gap: 0.3rem;
  font-size: 1.5rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 1rem;
  text-align: center;
  padding: 1rem 1rem;
  border-radius: 5rem;
  font-size: 18px;
}

.logout-button-no-access:hover {
  background-color: #bfbfbf;
}