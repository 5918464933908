.menu {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
}

.menu-select-bi {
    position: fixed;
}

.menu h1 {
    color: white;
}